import React from 'react'
import '../Styles/About.css'
const About = () => {

  const scrollDown = () => {
    window.scrollTo({
      top: 650,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <div className='header'>
        <h1 className='title'>Fresh Tropical Flowers</h1>
        <h1 className='about-content'>Since 2016, U.S.Q.P. has been importing top-quality, fresh-cut tropical flowers, greenery, and plants directly from growers from Asia and Central America regions weekly serving the U.S. market, especially the Bay Area.</h1>
        <button onClick={scrollDown} >Our Products </button>
      </div>
    </div>
  )
}

export default About