import React from 'react'
import '../Styles/Navbar.css'

const Navbar = () => {
    const [isDesktop, setDesktop] = React.useState(window.innerWidth > 850);
    const updateMedia = () => {
        setDesktop(window.innerWidth > 850);
    };
    React.useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const scrollDownProd = () => {
        window.scrollTo({
          top: 650,
          behavior: "smooth",
        });
      };

    const scrollDownCon = () => {
    window.scrollTo({
        top: 7000,
        behavior: "smooth",
    });
    };

    /* Scrolling for mobile */
    const scrollDownProdmobile = () => {
        window.scrollTo({
          top: 1000,
          behavior: "smooth",
        });
      };

    const scrollDownConmobile = () => {
    window.scrollTo({
        top: 20000,
        behavior: "smooth",
    });
    };

    return (
        <div className='nav-bar'>
            {isDesktop ? (
            <div className='nav-dimensions'>
                <img className='logo' src={require('../images/logo.png')}></img>
                <ul>
                    <li className='nav-items1' onClick={scrollDownProd}>Products</li>
                    <li className='nav-items2'onClick={scrollDownCon}>Contact Us</li>
                </ul>
            </div>
            ) : (
            <div>
                <section class="p-menu1">
                    <img className='logo' src={require('../images/logo.png')}></img>
                    <nav id="navbar" class="navigation" role="navigation">
                        <input id="toggle1" type="checkbox" />
                        <label class="hamburger1" for="toggle1">
                            <div class="top"></div>
                            <div class="meat"></div>
                            <div class="bottom"></div>
                        </label>
                    
                        <nav class="menu1">
                        <hr></hr>
                        <li class="link1" href="" onClick={scrollDownProdmobile}>Products</li>
                        <hr></hr>
                        <li class="link1" href="" onClick={scrollDownConmobile}>Contact Us</li>
                        </nav>
                    </nav>
                </section>
            </div>
            )}
        </div>
  )
}

export default Navbar