import './Styles/App.css'
import {data} from './data/flowersdata'
import Main from './websitepage/Main';
import Navbar from './websitepage/Navbar';
import About from './websitepage/About';
import Contact from './websitepage/Contact';
function App() {
  console.log(data.products[0].image)
  return (
    <div className="App">
      <Navbar></Navbar>
      <About></About>
      <Main></Main>
      <Contact></Contact>
    </div>
  );
}

export default App;
