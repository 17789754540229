import React from 'react'
import emailjs from'@emailjs/browser';
import '../Styles/Contact.css'
const Contact = () => {
  const form = React.useRef();
  const [success,setSucess] = React.useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_7nsjrjw', 'template_bju4irb', form.current, '7Itf-ikr49-KcHzhL')
      .then((result) => {
          console.log(result.text);
          console.log("success");
          setSucess(true)
          window.location.reload();
      }, (error) => {
          console.log(error.text);
          console.log("fail");
      });
  };

  return (
    <div>
        <div className='background-contact'>
            <li class="item">
              <div class="inner">
                  <h4 class="name">Looking to buy?</h4>
                  <p class="descr">Contact:</p>
                  <p class="descr-n">(510) 353-3450 </p>
                  <p className='or'>or</p>
                  {success && <h1 className='message-sent'>Message Sent!</h1>}

                  <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_name" className='customer-input' placeholder={"Name"}/>
                    <input className='email-input' type="email" name="user_email" placeholder={"E-mail"}/>
                    <textarea className='message-input'name="message" placeholder={"Message"}/>
                    <input className="submit" type="submit" value="Send" />
                  </form>
              </div>
            </li>
        </div>
    </div>
  )
}

export default Contact