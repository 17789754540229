
export const data = {
    products: [
        {
            name: 'Bouquet Big #3',
            image: require('../images/bouquetbig3.png')
        },
        {
            name: 'Bouquet Colocho',
            image: require('../images/bouquetcolocho.png')
        },
        {
            name: 'Bouquet Follaje Large',
            image: require('../images/bouquetfollaje.png')
        },
        {
            name: 'Bouquet Economico',
            image: require('../images/bouqueteconomico.png')
        },
        {
            name: 'Bouquet Colocho Heliconia',
            image: require('../images/bouquetcolochoheliconia.png')
        },
        {
            name: 'Bouquet Heliconia',
            image: require('../images/bouquetheliconia.png')
        },
        {
            name: 'Bouquet Ginger Red Colocho',
            image: require('../images/bouquetgingerredcollocho.png')
        },
        {
            name: 'Bouquet Ginger Red Cordyline',
            image: require('../images/bouquetgingerredcordyline.png')
        },
        {
            name: 'Bouquet Ginger Red Pincel',
            image: require('../images/bouquetgingerredpincel.png')
        },
        {
            name: 'Bouquet Ginger Red Phoenix',
            image: require('../images/bouquetgingerredphoenix.png')
        },
        {
            name: 'Bouquet Economico #1',
            image: require('../images/bouqueteconomico1.png')
        },
        {
            name: 'Bouquet Economico #2',
            image: require('../images/bouqueteconomico2.png')
        },
        {
            name: 'Bouquet Round Sofis #2',
            image: require('../images/bouquetroundsofis2.png')
            
        },
        {
            name: 'Bouquet Round Nina',
            image: require('../images/nina.png')
            
        },
        {
            name: 'Bouquet Round Porcelain',
            image: require('../images/bouquetroundporcelain.png')
            
        },
        {
            name: 'Ginger Pink',
            image: require('../images/gingerpink.png')
            
        },
        {
            name: 'Ginger Red',
            image: require('../images/gingerred.png')
            
        },
        {
            name: 'Heliconia Sexy Scarleth',
            image: require('../images/Heliconiasexyscarleth.png')
            
        },
        {
            name: 'Heliconia Sexy Red',
            image: require('../images/heliconiasexyred.png')
        },
        {
            name: 'Heliconia She Kong',
            image: require('../images/heliconiashekong.png')
        },
        {
            name: 'Heliconia Sexy Pink',
            image: require('../images/heliconiasexypink.png')
        },
        {
            name: 'Heliconia Sexy Purpura',
            image: require('../images/heliconiasexypurpura.png')
        },
        {
            name: 'Heliconia Rostrata',
            image: require('../images/heliconiarostrata.png')
        },
        {
            name: 'Heliconia Petrick',
            image: require('../images/heliconiapetrick.png')
        },
        {
            name: 'Heliconia Sunset Orange',
            image: require('../images/sunsetorange.png')
        },
        {
            name: 'Heliconia Fire Bird',
            image: require('../images/heliconiafirebird.png')
        },
        {
            name: 'Heliconia Angelina',
            image: require('../images/heliconiaangelina.png')
        },
        {
            name: 'Heliconia Orange Bihai',
            image: require('../images/heliconiaorangebihai.png')
        },
        {
            name: 'Heliconia Jardin',
            image: require('../images/heliconiajardin.png')
        },
        {
            name: 'Hala Pandanus',
            image: require('../images/halapandanus.png')
        },
        {
            name: 'Monstera',
            image: require('../images/monstera.png')
        },
        
    ]
}
