import React from 'react'
import { data } from '../data/flowersdata';
import '../Styles/Main.css'
const Main = () => {
  return (
    <div className="Main">
      <div className='main-title'>
        <h1>Our Products</h1>
        <h2>Tropical Flowers</h2>
        <hr></hr>
      </div>
      <div className='center'>
        {data.products.map(product => (
            <div className='wrapper'>
              <img className='product-img' src={product.image}></img>
              <h1 className='flower-name'>{product.name}</h1>
              <hr></hr>
            </div>
        ))}
      </div> 
    </div>
  )
}

export default Main